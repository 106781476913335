import "./style.css"
const Loader = () => {
  return (
/* From Uiverse.io by Donewenfu */ 
<div className="loader">
  <div className="justify-content-center jimu-primary-loading"></div>
</div>
  );
};

export default Loader;
